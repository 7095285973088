import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import Home3 from "./page/Home3/Home3";
import { LoadingScreen } from "./components/CrystalAnimations/CrystalAnimations";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    {/* <LoadingScreen /> */}
    <Home3 />
  </React.StrictMode>
);
