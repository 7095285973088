export const team = [
  {
    name: "Mustafa",
    role: "Engineer",
    url: "https://google.com",
  },
  {
    name: "Tushar",
    role: "Backend Dev",
    url: "https://google.com",
  },
  {
    name: "Pankaj",
    role: "Frontend Dev",
    url: "https://google.com",
  },
  {
    name: "Prajwal",
    role: "Backend Dev",
    url: "https://google.com",
  },
  {
    name: "Pranav",
    role: "Frontend Dev",
    url: "https://google.com",
  },
  {
    name: "Shubham",
    role: "Frontend Dev",
    url: "https://google.com",
  },
  {
    name: "Nawroz",
    role: "Frontend Dev",
    url: "https://google.com",
  },
  {
    name: "Amol",
    role: "Fullstack Dev",
    url: "https://google.com",
  },
  {
    name: "Saurabh",
    role: "Frontend Dev",
    url: "https://google.com",
  },
  {
    name: "Swamiraj",
    role: "Frontend Dev",
    url: "https://google.com",
  },
  {
    name: "Rishav",
    role: "Backend Dev",
    url: "https://google.com",
  },
  {
    name: "Bhagyashree",
    role: "BA",
    url: "https://google.com",
  },
  {
    name: "Yash",
    role: "BA",
    url: "https://google.com",
  },
  // {
  //   name: "Shraddha",
  //   role: "QA Engineer",
  //   url: "https://google.com",
  // },
  {
    name: "Omkar",
    role: "QA Engineer",
    url: "https://google.com",
  },
  {
    name: "Pratik",
    role: "QA Engineer",
    url: "https://google.com",
  },
  {
    name: "Sabah",
    role: "QA Engineer",
    url: "https://google.com",
  },
];

export const companies = [
  {
    contry: "India",
    company: "Taqafa Labs Technologies Private Limited",
    address:
      "Bootstart, Upper Ground Floor, Clover Hills Plaza, mall, NIBM Rd, Mohammed Wadi, Pune, Maharashtra 411048",
    address2: "Pune, India",
    lat: 18.46747257272781,
    lon: 73.90149702326721,
    color: "#FFD65A",
  },
  {
    contry: "Turkey",
    company: "NewMind",
    address:
      "Maslak Neighborhood, Yasyoncasi Street, Maslak 1453 F1 No:1G 34398 Sariyer/Istanbul",
    address2: "Istanbul, Turkey",
    lat: 41.05989471083286,
    lon: 28.97806468711818,
    color: "#FFD65A",
  },
];

export const services = [
  "Web Development",
  "Mobile Development",
  "UI/UX Design",
  "Addins Development",
  "Custom Software Development",
  "AI/ML Development",
  "Skilled Developers",
  "Data Visualization",
];

export const navigations = [
  {
    title: "Our Services",
    url: "#services",
    highlightColor: "#ff5733", // Vibrant Orange
  },
  {
    title: "Our Process",
    url: "#process",
    highlightColor: "#33c9ff", // Sky Blue
  },
  {
    title: "Our Team",
    url: "#team",
    highlightColor: "#33ff57", // Lime Green
  },
  {
    title: "Connect Us",
    url: "#connect",
    highlightColor: "#ff33c4", // Pink
  },
  {
    title: "Contact Us",
    url: "#contact",
    highlightColor: "#ffdd33", // Yellow
  },
  {
    title: "Our Offices",
    url: "#offices",
    highlightColor: "#8e44ad", // Purple
  },
];

export const ourProcess = [
  {
    content: "Discovery Call",
  },
  {
    content: "Planning & Analysis",
  },
  {
    content: "Design & Prototyping",
  },
  {
    content: "Implementation",
  },
  {
    content: "Deployment & Maintenance",
  },
];
