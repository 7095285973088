import React from "react";
import "./FlexGroup.scss";

const FlexGroup = ({ style = {}, children = <></>, gap = 10 }) => {
  return (
    <div style={{ ...style, gap }} className="flex-row-comp">
      {children}
    </div>
  );
};

export default FlexGroup;
