/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 public/models/one_rock_gltf/one_rockandone_crystal.gltf -t -r public 
*/

import React, { useEffect, useLayoutEffect, useRef } from "react";
import { Float, PivotControls, useGLTF, useHelper } from "@react-three/drei";
import { DirectionalLightHelper } from "three";
import { useFrame } from "@react-three/fiber";
import gsap from "gsap";
import { useControls } from "leva";

export default function PinkStone(props) {
  const { nodes, materials } = useGLTF(
    "/models/one_rock_gltf/one_rockandone_crystal.gltf"
  );

  // refs
  const directionalLightRef = useRef();
  const directionalLightRef2 = useRef();

  // useHelper(directionalLightRef, DirectionalLightHelper, 1, "red");
  // useHelper(directionalLightRef2, DirectionalLightHelper, 1, "blue");

  // const rotation = useControls("Rotation", {
  //   x: { value: 1.47, min: -10, max: 10, step: 0.01 },
  //   y: { value: -2.91, min: -10, max: 10, step: 0.01 },
  //   z: { value: 0.59, min: -10, max: 10, step: 0.01 },
  // });
  // [1.47, -2.91, 0.59]
  // const rotation2 = useControls("Rotation2", {
  //   x: { value: 0, min: -10, max: 10, step: 0.01 },
  //   y: { value: 0, min: -10, max: 10, step: 0.01 },
  //   z: { value: 0, min: -10, max: 10, step: 0.01 },
  // });

  const stoneRef = useRef();
  const scene = useRef();
  const scroll = useRef({ offset: 0 });
  const tl = useRef();


  useFrame((state, delta) => {
    // console.log(scroll?.offset);
    const maxScroll = document.body.scrollHeight - window.innerHeight;
    const scrollY = window.scrollY;
    scroll.current.offset = scrollY / maxScroll;

    const seekScroll = scroll.current.offset * tl.current?.duration();
    tl.current.seek(seekScroll);
  });

  useLayoutEffect(() => {
    tl.current = gsap.timeline({
      defaults: { duration: 3, ease: "power1.inOut" },
    });

    const setScale = (val) => ({ x: val, y: val, z: val });

    const groupTl = gsap.timeline({
      defaults: { duration: 1.2 },
      onComplete: () => {
        console.log("complete");

        tl.current
          .to(stoneRef.current.rotation, { y: -3 }, 0.5)
          // .to(stoneRef.current.position, { x: 2.2 }, 0.5)

          //   .to(robot.current.position, { z: -1 }, 5)

          .to(stoneRef.current.rotation, { y: -5 }, 4)
          // .to(stoneRef.current.position, { x: 2.2 }, 4)

          .to(stoneRef.current.rotation, { y: -9 }, 7)
          // .to(stoneRef.current.position, { x: 2 }, 7)

          .to(stoneRef.current.rotation, { y: -12 }, 10)
          .to(stoneRef.current.position, { x: 0 }, 10)

          // .to(stoneRef.current.scale, { ...setScale(1.7) }, 13)
          .to(stoneRef.current.rotation, { y: -15 }, 14)
          .to(stoneRef.current.position, { x: 2.2 }, 14)
          //   .to(box1.current.position, { x: 1, z: -1 }, 16)
          //   .to(box2.current.position, { x: -1, z: -1 }, 16)

          .to(stoneRef.current.position, { x: 1 }, 17)
          .to(stoneRef.current.rotation, { y: -17 }, 17)
          .to(stoneRef.current.rotation, { x: 0 }, 20)
          .to(stoneRef.current.position, { x: 0 }, 20);
      },
    });

    groupTl
      .add("simultan", { duration: 1, ease: "back.out(1.7)" })
      .from(
        stoneRef.current.position,
        {
          y: -2, // Starting position (off-screen or far left)
          ease: "back.out(2)",
        },
        "simultan"
      )
      .from(
        stoneRef.current.rotation,
        {
          y: -3,
        },
        "simultan"
      )
      // .from(
      //   directionalLightRef.current.position,
      //   {
      //     y: -1,
      //   },
      //   "simultan"
      // )
      // .from(
      //   directionalLightRef2.current.position,
      //   {
      //     y: 1.8,
      //   },
      //   "simultan"
      // );
  }, []);

  return (
    <>
      <ambientLight intensity={3} />
      <directionalLight
        color={"#D57DB6"}
        position={[-3, -1, 1]}
        intensity={3}
        ref={directionalLightRef}
      />
      <directionalLight
        ref={directionalLightRef2}
        color={"#74CCD8"}
        position={[3, 0.8, -5]}
        intensity={3}
      />
      <Float rotationIntensity={0.5}>
        <group {...props} dispose={null}>
          <group
            position={[0, 0.5, -0.071]}
            rotation={[0, 0, 0]}
            // rotation={[-2, -0.3, 3.5]}
            // rotation={[rotation2.x, rotation2.y, rotation2.z]}
            scale={1}
            ref={stoneRef}
          >
            <PivotControls enabled={true} visible={false} scale={1.5}>
              <group
                ref={scene}
                // rotation={[-1.18, 0.63, 0.09]}
                rotation={[1.47, -2.91, 0.59]}
              >
                <mesh
                  geometry={nodes.rock_PROXY_5m001.geometry}
                  material={materials["Material.002"]}
                />
                <mesh
                  geometry={nodes.rock_PROXY_5m001_1.geometry}
                  material={materials["Material.007"]}
                />
              </group>
            </PivotControls>
          </group>
        </group>
      </Float>
    </>
  );
}

useGLTF.preload("/models/one_rock_gltf/one_rockandone_crystal.gltf");
