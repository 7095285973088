export function classNames() {
  let classString = "";
  for (const argument of arguments) {
    if (argument) {
      if (classString === "") {
        classString = `${argument}`;
      } else {
        classString = classString + ` ${argument}`;
      }
    }
  }

  return classString;
}

export function getTimeRemaining(targetDate) {
  // Get the current date and time
  const now = new Date();

  // Calculate the difference in milliseconds
  const timeDifference = targetDate - now;

  // Ensure the difference is non-negative
  if (timeDifference <= 0) {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  // Convert the difference to seconds
  const seconds = Math.floor(timeDifference / 1000);

  // Calculate days, hours, minutes, and remaining seconds
  const days = Math.floor(seconds / (24 * 60 * 60));
  const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
  const minutes = Math.floor((seconds % (60 * 60)) / 60);
  const remainingSeconds = seconds % 60;

  // Return the result as an object
  return {
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: remainingSeconds,
  };
}

export const redirectToGoogleMaps = (lat, long, zoomLevel = 12, type = "k") => {
  // z is the zoom level (1-20)
  // t is the map type ("m" map, "k" satellite, "h" hybrid, "p" terrain, "e" GoogleEarth)
  // q is the search query, if it is prefixed by loc: then google assumes it is a lat lon separated by a +

  window.open(
    `http://maps.google.com/maps?z=${zoomLevel}&t=${type}&q=loc:${lat}+${long}`,
    "_blank"
  );
};
