import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import "./ConnectUs.scss";

// components
import FG from "../FlexGroup/FlexGroup";
import Select from "react-select";
import { countryOptions } from "../../constants/contryCodes";
import { classNames } from "../../services/util";
import { label } from "three/webgpu";
import { services } from "../../constants";

const FlexGroup = (props) => {
  const { style = {} } = props;
  return <FG {...props} style={{ ...style, flexWrap: "wrap" }} />;
};

const CUInput = forwardRef(
  (
    {
      placeholder = "My name is",
      value = "",
      onChange = () => {},
      onKeyDown = () => {},
      isVisible = true,
    },
    ref
  ) => {
    return (
      <input
        ref={ref}
        className="cu-input"
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={onKeyDown}
      />
    );
  }
);

const CUSelect = forwardRef(
  (
    {
      options = [],
      value = "",
      onChange = () => {},
      placeholder = "",
      handleNext = () => {},
    },
    ref
  ) => {
    const [active, setActive] = useState(false);

    useImperativeHandle(ref, () => ({
      focus: () => {
        setActive(true);
      },
    }));

    return (
      <div className="cu-select" tabIndex={0} onBlur={() => setActive(false)}>
        <div
          className={classNames("cus-input", active ? "active" : "")}
          onClick={() => setActive(!active)}
        >
          <div className={classNames("cus-content", value ? "active" : "")}>
            {value || placeholder}
          </div>
          <i
            className={classNames(
              "cus-arrow icon-chevron-down",
              active ? "active" : ""
            )}
          />
        </div>
        {active && (
          <div className="cus-options">
            {options.map((option) => (
              <div
                className="cus-option"
                onClick={() => {
                  onChange(option);
                  handleNext({ target: { value: option.label }, key: "Enter" });
                  setActive(false);
                }}
              >
                {option.label}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
);

const AnimatedText = ({ children, delay = 50 }) => {
  const [displayedText, setDisplayedText] = useState("");

  useEffect(() => {
    let currentIndex = 0;
    const intervalId = setInterval(() => {
      if (currentIndex <= children.length) {
        setDisplayedText(children.slice(0, currentIndex));
        currentIndex++;
      } else {
        clearInterval(intervalId);
      }
    }, delay);

    return () => clearInterval(intervalId);
  }, [children, delay]);

  return <span>{displayedText}</span>;
};

const org = [
  { label: "Individual", value: "Individual" },
  { label: "Organization", value: "Organization" },
  { label: "Team", value: "Team" },
];

const ConnectUs = () => {
  // data
  const [name, setName] = useState("");
  const [profession, setProfession] = useState(null);
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [service, setService] = useState(null);
  const [website, setWebsite] = useState("");
  const [phone, setPhone] = useState("");

  const [visibleIndex, setVisibleIndex] = useState(0);

  // refs
  const nameRef = useRef(null);
  const professionRef = useRef(null);
  const companyRef = useRef(null);
  const websiteRef = useRef(null);
  const serviceRef = useRef(null);
  const emailRef = useRef(null);
  const phoneRef = useRef(null);

  const handleNext = (e, index) => {
    if (e.key === "Enter" && e.target.value.trim() !== "") {
      if (e.target.value === "Individual" && index === 1) {
        setVisibleIndex(4); // Skip to service input
        setTimeout(() => {
          focusNext(4);
        }, 500);
      } else {
        setVisibleIndex(index + 1);
        setTimeout(() => {
          focusNext(index + 1);
        }, 200);
      }
    }
  };

  const focusNext = (index) => {
    setTimeout(() => {
      if (index < 6) {
        const nextRef = [
          nameRef,
          professionRef,
          companyRef,
          websiteRef,
          serviceRef,
          emailRef,
          phoneRef,
        ][index];
        nextRef?.current?.focus();
      }
    }, 200);
  };

  return (
    <div className="connect-us">
      {visibleIndex >= 0 && (
        <div>
          My name is{" "}
          <CUInput
            placeholder="YOUR FULL NAME"
            value={name}
            onChange={setName}
            onKeyDown={(e) => handleNext(e, 0)}
            isVisible={visibleIndex >= 0}
            ref={nameRef}
          />
        </div>
      )}
      <FlexGroup>
        {visibleIndex >= 1 && (
          <FlexGroup gap={10}>
            <AnimatedText>and I can be represented as </AnimatedText>
            {/* Make a dropdown */}
            {/* Individual, organization, or a team */}
            {/* if individual, then only ask contact number / email */}
            {/* if organization, then ask company name and website url & contact number/ email */}
            <CUSelect
              options={org}
              value={profession?.label}
              onChange={setProfession}
              ref={professionRef}
              placeholder="SELECT PROFESSION"
              handleNext={(e) => handleNext(e, 1)}
            />
          </FlexGroup>
        )}

        {visibleIndex >= 2 && profession?.value !== "Individual" && (
          <FlexGroup gap={10}>
            <AnimatedText>at</AnimatedText>{" "}
            <CUInput
              placeholder="YOUR COMPANY"
              value={company}
              onChange={setCompany}
              onKeyDown={(e) => handleNext(e, 2)}
              isVisible={visibleIndex >= 2}
              ref={companyRef}
            />
          </FlexGroup>
        )}
      </FlexGroup>
      {visibleIndex >= 3 && profession?.value !== "Individual" && (
        <FlexGroup gap={10}>
          <AnimatedText>And my company website is</AnimatedText>{" "}
          <CUInput
            placeholder="YOUR WEBSITE"
            value={website}
            onChange={setWebsite}
            onKeyDown={(e) => handleNext(e, 3)}
            isVisible={visibleIndex >= 3}
            ref={websiteRef}
          />
        </FlexGroup>
      )}
      <FlexGroup>
        {visibleIndex >= 4 && (
          <FlexGroup gap={10}>
            <AnimatedText>I am looking for</AnimatedText>{" "}
            <CUSelect
              options={services?.map((service) => ({
                label: service,
                value: service,
              }))}
              value={service?.label}
              onChange={setService}
              ref={serviceRef}
              placeholder="YOUR SERVICE"
              handleNext={(e) => handleNext(e, 4)}
            />{" "}
          </FlexGroup>
        )}

        {visibleIndex >= 5 && <div>You can</div>}
      </FlexGroup>

      {visibleIndex >= 5 && (
        <>
          <FlexGroup gap={10}>
            <AnimatedText>reach me at</AnimatedText>{" "}
            <CUInput
              placeholder="YOUR EMAIL"
              value={email}
              onChange={setEmail}
              onKeyDown={(e) => handleNext(e, 5)}
              isVisible={visibleIndex >= 5}
              ref={emailRef}
            />{" "}
          </FlexGroup>
          <FlexGroup gap={10}>
            <AnimatedText>Or</AnimatedText> <PhoneInput />
          </FlexGroup>
        </>
      )}

      <AnimatedText>to get things started.</AnimatedText>

      <button>Submit</button>
    </div>
  );
};

const PhoneInput = () => {
  const [selectedCountry, setSelectedCountry] = useState(countryOptions[0]);
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  return (
    <div className="phone-input">
      <FlexGroup>
        <CUSelect
          options={countryOptions}
          value={selectedCountry.label}
          onChange={handleCountryChange}
        />

        <CUInput
          type="text"
          placeholder="YOUR PHONE"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
        />
      </FlexGroup>
    </div>
  );
};

export default ConnectUs;
