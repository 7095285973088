import React, { Suspense, useEffect, useRef, useState } from "react";
import "./Home3.scss";

// services
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { ReactComponent as LinkedIn } from "../../assets/svgs/linkedin.svg";
import {
  companies,
  navigations,
  ourProcess,
  services,
  team,
} from "../../constants";
import TechVideo from "../../assets/videos/technologies.mp4";
import ConnectUs from "../../components/ConnectUs/ConnectUs";
import { classNames, redirectToGoogleMaps } from "../../services/util";

import { ReactComponent as LongArrow } from "../../assets/svgs/long-arrow.svg";

import { ReactComponent as Linkedin } from "../../assets/svgs/linkedin-black.svg";
import { ReactComponent as Youtube } from "../../assets/svgs/youtube-black.svg";
import { ReactComponent as Insta } from "../../assets/svgs/instagram-black.svg";
import { ReactComponent as Fb } from "../../assets/svgs/facebook-black.svg";
import { Canvas, useFrame } from "@react-three/fiber";
import { OrbitControls, Sparkles, Stars, useHelper } from "@react-three/drei";
import GlobeGeometric from "../../Models/GlobeGeometric";
import { DirectionalLightHelper } from "three";
import Navbar from "../../components/Navbar/Navbar";
import CrystalAnimations from "../../components/CrystalAnimations/CrystalAnimations";

// register plugins
gsap.registerPlugin(ScrollTrigger);

const mobileView = window.innerWidth < 576;

const Home3 = () => {
  const socials = [
    {
      icon: <Linkedin className="social-icons" />,
      url: "",
    },
    {
      icon: <Youtube className="social-icons" />,
      url: "",
    },
    {
      icon: <Insta className="social-icons" />,
      url: "",
    },
    {
      icon: <Fb className="social-icons" />,
      url: "",
    },
  ];

  useGSAP(() => {
    console.log("window.innerWidth", window.innerWidth);

    // s1 Hero section
    gsap.from(".s1-heading span", {
      y: -200,
      opacity: 0,
      delay: 0.7,
      // ease: "back.out(2)",
      scrollTrigger: {
        trigger: ".s1-heading",
        start: "top 90%",
        end: "top 10%",
        // markers: true,
        toggleActions: "play reverse play reverse",
      },
    });

    gsap.from(".s1-subheading span", {
      y: -200,
      opacity: 0,
      delay: 0.6,
      //   ease: "back.out(2)",
      scrollTrigger: {
        trigger: ".s1-subheading",
        start: "top 90%",
        end: "top 10%",
        toggleActions: "play reverse play reverse",
      },
    });

    gsap.from(".s1-content div", {
      y: -200,
      opacity: 0,
      delay: 0.5,
      scrollTrigger: {
        trigger: ".s1-content",
        start: "top 90%",
        end: "top 10%",
        toggleActions: "play reverse play reverse",
      },
    });

    /// page navigations
    gsap.from(".navigations .navigation", {
      opacity: 0,
      y: -100,
      stagger: 0.1,
      scrollTrigger: {
        trigger: ".navigations",
        start: "top 90%",
        end: "top 10%",
        toggleActions: "play reverse play reverse",
      },
    });

    gsap.from(".scroll-down", {
      opacity: 0,
      y: -150,
      scrollTrigger: {
        trigger: ".scroll-down",
        start: "top 90%",
        end: "top 10%",
        toggleActions: "play reverse play reverse",
      },
    });

    // services section
    gsap.from(".s2", {
      opacity: 0,
      y: 300,
      scrollTrigger: {
        trigger: ".s2",
        start: "top 90%",
        // end: "top 10%",
        toggleActions: "play reverse play reverse",
      },
    });

    gsap.to(".s3-body .s3-process span", {
      opacity: 1,
      stagger: 0.1,
      scrollTrigger: {
        trigger: ".s3-body",
        start: "top 70%",
        end: "top 10%",
        scrub: true,
        // markers: true,
      },
    });

    // Team section
    gsap.from(".sec-5", {
      opacity: 0,
      y: 200,
      scrollTrigger: {
        trigger: ".sec-5",
        start: "top 90%",
        // end: "top 10%",
        toggleActions: "play reverse play reverse",
      },
    });

    /// Connect Us
    gsap.from(".s8-canvas-elem", {
      // opacity: 0,
      y: 200,
      scrollTrigger: {
        trigger: ".s8-canvas-elem",
        start: "top 110%",
        scrub: 1,
        end: "top 50%",
        toggleActions: "play reverse play reverse",
      },
    });

    /// contact us section
    gsap.from(".s8", {
      opacity: 0,
      y: 200,
      scrollTrigger: {
        trigger: ".s8",
        start: "top 90%",
        // end: "top 10%",
        toggleActions: "play reverse play reverse",
      },
    });

    gsap.from(".footer .footer-column", {
      opacity: 0,
      y: 200,
      stagger: 0.1,
      scrollTrigger: {
        trigger: ".footer",
        start: "top 90%",
        // end: "top 10%",
        toggleActions: "play reverse play reverse",
      },
    });

    if (mobileView) {
    } else {
    }
  });

  return (
    <>
      <CrystalAnimations />
      <StarsWrapper />
      <Suspense fallback={<div className="fallback-loader"></div>}>
        <div className="home3">
          <Navbar />
          <section className="s1">
            <h1 className="s1-heading">
              <span>Next Smart Move</span>
            </h1>
            <p className="s1-subheading">
              <span>Ahead of the Curve</span>
            </p>
            <div className="s1-content">
              <div>
                Our <span>smart solutions</span> are built to match your{" "}
                <span>unique business</span> needs, and we provide hands-on
                training to ensure you maximize the benefits of our technology.
                We handle your software development projects from start to
                finish, delivering <span>digital transformation</span> across
                industries, with a specialization in <span>legal tech</span>.
                Using <span>agile methodologies</span>, we ensure timely
                delivery with customized, intuitive dashboards
                {/* and{" "}<span>Three JS charts</span>  */}
                that offer <span>seamless performance</span> and{" "}
                <span>clear data insights.</span>
              </div>
            </div>
          </section>
          <section id="page-navigations">
            <section className="navigations">
              {navigations?.map((navigation, index) => (
                <a
                  href={navigation?.url}
                  className="navigation"
                  key={`navigation-${index}`}
                >
                  <span
                    className="nav-index"
                    style={{
                      "--highlight-color": navigation.highlightColor, // Set a CSS variable
                    }}
                  >
                    {index + 1}
                  </span>
                  [<span className="nav-title">{navigation?.title}</span>]
                </a>
              ))}
            </section>
            <section className="scroll-down">
              <div className="sd-content">Scroll Down</div>
              <div className="sd-description">to discover more</div>
              <a href="#services">
                <div className="sd-mouse">
                  <div className="sdm-scrollbar"></div>
                </div>
              </a>
            </section>
          </section>

          <div className="s2-wrapper" id="services">
            <section className="s2">
              <SectionTitle className="s2-title" content="Our Services" />
              <div className="s2-body">
                {services?.map((service, index) => (
                  <ServiceRow content={service} key={`service-${index}`} />
                ))}
              </div>
            </section>
          </div>

          <section className="s3" id="process">
            <SectionTitle content="Our Process" />
            <div className="s3-body">
              {ourProcess.map((process, index) => (
                <div className="s3-process" key={`s3-process-${index}`}>
                  {process.content.split("")?.map((el, index) => (
                    <span
                      key={`s3-process-${index}-${el}`}
                      dangerouslySetInnerHTML={{ __html: el }}
                    ></span>
                  ))}
                </div>
              ))}
            </div>
          </section>

          {/* <section className="s4" id="s4-section">
          <SectionTitle content="Our Process" align="center" />

          <div className="s4-body">
            {ourProcess?.map((process, index) => (
              <ProcessCard
                data={process}
                index={index + 1}
                key={`s4-process-${index}`}
              />
            ))}
          </div>
        </section> */}

          {/* Team section */}
          <Section5 />

          <div className="s6">
            <video src={TechVideo} autoPlay loop muted playsInline />
          </div>
          <div className="s7" id="connect">
            <SectionTitle
              className="s7-title"
              content="Connect Us"
              style={{ marginBottom: 50 }}
            />
            <ConnectUs />
          </div>
          <div className="s8" id="contact">
            <SectionTitle
              content="CONTACT US"
              dark
              align={mobileView ? "center" : "right"}
            />
            <div className="s8-content" id="s8-content">
              <EarthCanvas />
              <div className="s8-right">
                <div className="s8-title">Keep in touch</div>
                <div className="s8-description">Start a conversation</div>
                <div className="s8-mail">info@nextsmartmove.ai</div>

                <div className="s8-socials">
                  {socials?.map((data, index) => (
                    <SocialMediaIcon
                      index={index}
                      data={data}
                      key={`social-media-icon-${index}`}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <Footer />
          <section className="copy">
            <div className="copy-content">
              <span>
                © 2025 Taqafa Labs Technologies Private Limited. All rights
                reserved.
              </span>
            </div>
            <div className="copy-navigations">
              <div className="cn-menu">Terms & Services</div>
              <div className="cn-menu">Privacy Policy</div>
              <div className="cn-menu">Cookie Policy</div>
            </div>
          </section>
        </div>
      </Suspense>
    </>
  );
};

const SocialMediaIcon = ({ data, index }) => {
  const iconRef = useRef();
  useGSAP(() => {
    gsap.from(iconRef.current, {
      opacity: 0,
      y: 100,
      duration: 0.7,
      delay: 0.4 + index / 10,
      scrollTrigger: {
        trigger: iconRef.current,
        start: "top 100%", // Animation starts when top of the element hits 80% of viewport height
        end: "top 30%", // Animation ends when top of the element hits 30% of viewport height
        toggleActions: "restart none none none",
        // markers: true,
      },
    });
  });

  return (
    <span ref={iconRef} className="social-media-icon">
      {data?.icon}
    </span>
  );
};

const SectionTitle = ({
  content,
  style,
  align = "left",
  dark = false,
  id = null,
  className = "",
}) => {
  return (
    <div
      className={classNames("section-title", align, className)}
      style={style}
      id={id}
    >
      <span className={classNames("section-title-content", dark ? "dark" : "")}>
        {content}
      </span>
    </div>
  );
};

const ServiceRow = ({ content }) => {
  const sliderRef = useRef(null);
  useGSAP(() => {
    if (mobileView) {
      gsap.to(sliderRef.current, {
        x: "-100vw",
        scrollTrigger: {
          trigger: sliderRef.current,
          start: "top 90%",
          end: "top 50%",
          scrub: 1,
          // toggleActions: "play reverse play reverse",
        },
      });
    } else {
      gsap.to(sliderRef.current, {
        x: "-100vw",
        scrollTrigger: {
          trigger: sliderRef.current,
          start: "top 105%",
          end: "top 40%",
          scrub: 1,
          // toggleActions: "play reverse play reverse",
        },
      });
    }
  });
  return (
    <div className="service-row">
      <div className="sr-slider" ref={sliderRef}>
        <div className="service-row-heading left">
          {Array(5)
            .fill(0)
            .map((_, index) => (
              <span
                className="service-row-content"
                key={`${content}-reverse-${index}`}
              >
                {content}
              </span>
            ))}
        </div>
        <div className="service-row-heading">
          <span className="service-row-content active">{content}</span>
          {Array(10)
            .fill(0)
            .map((_, index) => (
              <span className="service-row-content" key={`${content}-${index}`}>
                {content}
              </span>
            ))}
        </div>
      </div>
    </div>
  );
};

const Section5 = () => {
  return (
    <div className="sec-5" id="team">
      <SectionTitle id={"section-5-title"} content="Team" align="center" />

      <div className="s5-slider-container">
        <div className="s5-slider" id="s5-slider">
          {team?.map((user, index) => (
            <TeamCard data={user} key={`user-${index}`} />
          ))}
        </div>
      </div>
      <div className="s5-slider-container">
        <div className="s5-slider reverse" id="s5-slider-2">
          {team.reverse()?.map((user, index) => (
            <TeamCard data={user} key={`user-${index}`} />
          ))}
        </div>
      </div>
    </div>
  );
};

const TeamCard = ({ data = {} }) => {
  return (
    <div className="team-card">
      <div className="tc-name">{data?.name}</div>
      <div className="tc-role">
        {data?.role} <LinkedIn className="linked-in-icon" />
      </div>
    </div>
  );
};

const FooterColumn = ({ data = {} }) => {
  // useGSAP(() => {
  //   gsap.from(".fc-address span", {
  //     opacity: 0,
  //     duration: 1,
  //     scrollTrigger: ".footer-column",
  //     stagger: 0.1,
  //   });
  // });

  return (
    <div className="footer-column">
      <div className="fc-contry">{data?.contry}</div>
      <div className="fc-company">{data?.company}</div>
      <div className="fc-address">
        {data?.address}
        {/* {data?.address?.split("").map((word, index) => (
          <span key={`${data?.address}-${word}-${index}`}>{word}</span>
        ))} */}
      </div>
      <div
        className="fc-redirect"
        onClick={() => redirectToGoogleMaps(data?.lat, data?.lon)}
      >
        <LongArrow className="fc-arrow" /> <span>View on map</span>
      </div>
    </div>
  );
};

const Footer = () => {
  return (
    <div className="footer-wrapper-box">
      <div className="fwb-box" id="offices">
        <div className="f-title">Our offices</div>
        <section className="footer">
          <FooterColumn data={companies[0]} />
          <FooterColumn data={companies[1]} />
        </section>
      </div>
    </div>
  );
};

const EarthCanvas = () => {
  return (
    <div className="s8-canvas-elem">
      <Canvas
        shadows
        camera={{ position: [5, 5, 10], fov: 50 }}
        className="s8-canvas"
      >
        <OrbitControls
          enableZoom={false}
          enablePan={false}
          minPolarAngle={Math.PI / 2}
          maxPolarAngle={Math.PI / 2}
        />
        <directionalLight position={[-2, 0, 3]} intensity={1.5} castShadow />
        <directionalLight position={[2, 2, -2]} intensity={2} castShadow />
        {/* Ambient Light for overall illumination */}
        <ambientLight intensity={0.5} />
        {/* Point Light */}
        <pointLight position={[-5, 5, -5]} intensity={0.7} />
        {/* Spot Light */}
        <spotLight
          position={[10, 10, 10]}
          angle={0.2}
          intensity={0.8}
          castShadow
        />

        <spotLight
          position={[10, 10, 10]}
          angle={0.2}
          intensity={0.8}
          castShadow
        />
        <GlobeGeometric />
      </Canvas>
    </div>
  );
};

const ProcessCard = ({ data = {}, index = 1 }) => {
  return (
    <div className={classNames("process-card", `index-${index}`)}>
      <div className={classNames("plane", `index-${index}`)}>
        <div className="plane-wing"></div>
        <div className="plane-wing right"></div>
      </div>
      <div className="pc-count">{index}</div>
      <div className="pc-content">{data?.content}</div>
    </div>
  );
};

const StarsWrapper = () => {
  return (
    <div className="stars">
      <div className="stars-comet"></div>
      <div className="stars-comet comet-2"></div>
      <div className="stars-comet comet-3"></div>
      <div className="stars-comet comet-4"></div>
      <div className="stars-comet comet-5"></div>
      <Canvas>
        <CameraController />
        <Stars
          radius={50}
          depth={50}
          count={700}
          factor={5}
          saturation={0}
          fade
          speed={1}
        />
      </Canvas>
    </div>
  );
};

const CameraController = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (event) => {
      const { innerWidth, innerHeight } = window;

      // Normalize mouse position (-1 to 1)
      const x = (event.clientX / innerWidth) * 1 - 1;
      const y = -(event.clientY / innerHeight) * 1 + 1;

      setMousePosition({ x, y });
    };

    window.addEventListener("mousemove", handleMouseMove);
    return () => window.removeEventListener("mousemove", handleMouseMove);
  }, []);

  useFrame(({ camera }) => {
    const radius = 0.5; // Distance of the camera from the center
    const sensitivity = 0.05; // Adjust movement sensitivity

    // Smoothly interpolate the camera angle based on mouse movement
    const targetAngleX = mousePosition.x * Math.PI * sensitivity; // Rotate left/right
    const targetAngleY = mousePosition.y * Math.PI * 0.02; // Slight tilt up/down

    // Convert polar coordinates to Cartesian
    camera.position.x = Math.sin(targetAngleX) * radius;
    camera.position.z = Math.cos(targetAngleX) * radius;
    camera.position.y = targetAngleY * radius;

    // Ensure camera always looks at the center
    camera.lookAt(0, 0, 0);
  });

  return null;
};

export default Home3;
