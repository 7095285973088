/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 public/models/final-globe/globe_geometric.gltf -t -r public 
*/

import React, { forwardRef, useRef } from "react";
import { Html, useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import NmLogo from "../assets/newmind-logo.png";
import NsmLogo from "../assets/logo-black.png";
import "./Models.scss";
import { companies } from "../constants";

const GlobeGeometric = forwardRef((props, ref) => {
  const { nodes, materials } = useGLTF(
    "/models/final-globe/globe_geometric.gltf"
  );

  const meshRef = useRef();

  useFrame(() => {
    if (meshRef.current) {
      meshRef.current.rotation.y += 0.004;
    }
  });

  const latLonToCartesian = (lat, lon, radius) => {
    const phi = (90 - lat) * (Math.PI / 180);
    const theta = (lon + 180) * (Math.PI / 180);
    const x = -(radius * Math.sin(phi) * Math.cos(theta));
    const z = radius * Math.sin(phi) * Math.sin(theta);
    const y = radius * Math.cos(phi);
    return [x, y, z];
  };

  return (
    <group ref={meshRef} {...props} dispose={null} scale={2}>
      {companies.map((country, idx) => {
        const position = latLonToCartesian(country.lat, country.lon, 1.55);
        return <CountryMarker key={idx} position={position} contry={country} />;
      })}

      <group rotation={[-Math.PI / 2, 0, 4.76]} scale={1.5}>
        <mesh
          geometry={nodes.Object_2.geometry}
          material={materials["Material.009"]}
        />
        <mesh
          geometry={nodes.Object_2_1.geometry}
          material={materials["Material.008"]}
        />
      </group>
    </group>
  );
});

const CountryMarker = ({ position, contry }) => {
  const markerRef = useRef();

  return (
    <mesh ref={markerRef} position={position}>
      {/* Marker Sphere */}
      <sphereGeometry args={[0.04, 32, 32]} />
      <meshNormalMaterial color={contry?.color || "#FF0000"} />
      <spotLight color="green" intensity={1} />

      {/* Tooltip */}
      <Html
        distanceFactor={10} // Adjust tooltip size
        position={[0, 0.1, 0]} // Slightly above the marker
        occlude="raycast"
        className="contry-marker-box"
      >
        <div className="cmb-initial-content">
          <img src={contry?.company === "NewMind" ? NmLogo : NsmLogo} alt="" />
          <div className="cmbi-name">{contry?.address2}</div>
        </div>
        <div className="cmb-hovered-content">
          <div className="cmb-images">
            <img
              src="https://d1di04ifehjy6m.cloudfront.net/media/filer_public/67/ca/67cac7fa-1853-4f1d-95ed-db1ed6b38ec5/adobestock_212026013_2.png"
              alt=""
              className="cmb-img"
            />
            <img
              src="https://d1di04ifehjy6m.cloudfront.net/media/filer_public/67/ca/67cac7fa-1853-4f1d-95ed-db1ed6b38ec5/adobestock_212026013_2.png"
              alt=""
              className="cmb-img"
            />
          </div>
          <div className="cmb-title">{contry.contry}</div>
          <div className="cmb-name">{contry.company}</div>
          <div className="cmb-desc">{contry.address}</div>
        </div>
      </Html>
    </mesh>
  );
};

export default GlobeGeometric;

useGLTF.preload("/models/final-globe/globe_geometric.gltf");
