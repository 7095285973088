import React, { Suspense, useEffect, useState } from "react";

// models
import { Canvas } from "@react-three/fiber";
import PinkStone from "../../Models/PinkStone";
import PinkStoneMobile from "../../Models/PinkStoneMobile";
import { Html, useProgress } from "@react-three/drei";

const mobileView = window.innerWidth < 576;

const CrystalAnimations = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  return (
    <>
      {isLoading && <LoadingScreen progress={progress} />}
      <Canvas
        style={{
          width: "100vw",
          height: "100vh",
          position: "fixed",
          zIndex: isLoading ? 999 : -1,
          // background: "#000",
        }}
        camera={{ position: [0, 0, 5], fov: 45 }}
      >
        <Suspense
          fallback={
            <CheckLoading
              onLoadingComplete={() => setIsLoading(false)}
              setProgress={setProgress}
              startLoading={() => setIsLoading(true)}
            />
          }
        >
          {mobileView ? <PinkStoneMobile /> : <PinkStone />}
        </Suspense>
      </Canvas>
    </>
  );
};

const CheckLoading = ({
  onLoadingComplete = () => {},
  setProgress = () => {},
  startLoading = () => {},
}) => {
  const { progress } = useProgress();
  console.log("progress", progress);

  useEffect(() => {
    startLoading();
    return () => {
      onLoadingComplete();
    };
  }, []);

  useEffect(() => {
    setProgress(progress);
    if (progress === 100) {
      onLoadingComplete();
    }
  }, [progress]);

  return null;
};

export const LoadingScreen = ({ progress = 0 }) => {
  return (
    <div className="loading-screen">
      <div className="loading-screen-content">
        <div className="loading-screen-content-title">
          <span
            className="text-fill"
            style={{ width: `${Math.floor(progress)}%` }}
          >
            Next Smart Move
          </span>
          Next Smart Move
        </div>
        <div className="loading-screen-content-progress">
          {Math.floor(progress)}%
        </div>
      </div>
    </div>
  );
};

export default CrystalAnimations;
