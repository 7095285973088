export const countryOptions = [
    { value: "+1", label: "🇺🇸 +1 (US)", code: "US" },
    { value: "+7", label: "🇷🇺 +7 (RU)", code: "RU" },
    { value: "+20", label: "🇪🇬 +20 (EG)", code: "EG" },
    { value: "+27", label: "🇿🇦 +27 (ZA)", code: "ZA" },
    { value: "+30", label: "🇬🇷 +30 (GR)", code: "GR" },
    { value: "+31", label: "🇳🇱 +31 (NL)", code: "NL" },
    { value: "+32", label: "🇧🇪 +32 (BE)", code: "BE" },
    { value: "+33", label: "🇫🇷 +33 (FR)", code: "FR" },
    { value: "+34", label: "🇪🇸 +34 (ES)", code: "ES" },
    { value: "+36", label: "🇭🇺 +36 (HU)", code: "HU" },
    { value: "+39", label: "🇮🇹 +39 (IT)", code: "IT" },
    { value: "+40", label: "🇷🇴 +40 (RO)", code: "RO" },
    { value: "+41", label: "🇨🇭 +41 (CH)", code: "CH" },
    { value: "+43", label: "🇦🇹 +43 (AT)", code: "AT" },
    { value: "+44", label: "🇬🇧 +44 (GB)", code: "GB" },
    { value: "+45", label: "🇩🇰 +45 (DK)", code: "DK" },
    { value: "+46", label: "🇸🇪 +46 (SE)", code: "SE" },
    { value: "+47", label: "🇳🇴 +47 (NO)", code: "NO" },
    { value: "+48", label: "🇵🇱 +48 (PL)", code: "PL" },
    { value: "+49", label: "🇩🇪 +49 (DE)", code: "DE" },
    { value: "+51", label: "🇵🇪 +51 (PE)", code: "PE" },
    { value: "+52", label: "🇲🇽 +52 (MX)", code: "MX" },
    { value: "+53", label: "🇨🇺 +53 (CU)", code: "CU" },
    { value: "+54", label: "🇦🇷 +54 (AR)", code: "AR" },
    { value: "+55", label: "🇧🇷 +55 (BR)", code: "BR" },
    { value: "+56", label: "🇨🇱 +56 (CL)", code: "CL" },
    { value: "+57", label: "🇨🇴 +57 (CO)", code: "CO" },
    { value: "+58", label: "🇻🇪 +58 (VE)", code: "VE" },
    { value: "+60", label: "🇲🇾 +60 (MY)", code: "MY" },
    { value: "+61", label: "🇦🇺 +61 (AU)", code: "AU" },
    { value: "+62", label: "🇮🇩 +62 (ID)", code: "ID" },
    { value: "+63", label: "🇵🇭 +63 (PH)", code: "PH" },
    { value: "+64", label: "🇳🇿 +64 (NZ)", code: "NZ" },
    { value: "+65", label: "🇸🇬 +65 (SG)", code: "SG" },
    { value: "+66", label: "🇹🇭 +66 (TH)", code: "TH" },
    { value: "+81", label: "🇯🇵 +81 (JP)", code: "JP" },
    { value: "+82", label: "🇰🇷 +82 (KR)", code: "KR" },
    { value: "+84", label: "🇻🇳 +84 (VN)", code: "VN" },
    { value: "+86", label: "🇨🇳 +86 (CN)", code: "CN" },
    { value: "+90", label: "🇹🇷 +90 (TR)", code: "TR" },
    { value: "+91", label: "🇮🇳 +91 (IN)", code: "IN" },
    { value: "+92", label: "🇵🇰 +92 (PK)", code: "PK" },
    { value: "+93", label: "🇦🇫 +93 (AF)", code: "AF" },
    { value: "+94", label: "🇱🇰 +94 (LK)", code: "LK" },
    { value: "+95", label: "🇲🇲 +95 (MM)", code: "MM" },
    { value: "+98", label: "🇮🇷 +98 (IR)", code: "IR" },
    { value: "+212", label: "🇲🇦 +212 (MA)", code: "MA" },
    { value: "+213", label: "🇩🇿 +213 (DZ)", code: "DZ" },
    { value: "+216", label: "🇹🇳 +216 (TN)", code: "TN" },
    { value: "+218", label: "🇱🇾 +218 (LY)", code: "LY" },
    { value: "+234", label: "🇳🇬 +234 (NG)", code: "NG" },
    { value: "+971", label: "🇦🇪 +971 (AE)", code: "AE" },
    { value: "+972", label: "🇮🇱 +972 (IL)", code: "IL" },
    { value: "+974", label: "🇶🇦 +974 (QA)", code: "QA" },
    { value: "+975", label: "🇧🇹 +975 (BT)", code: "BT" },
    { value: "+977", label: "🇳🇵 +977 (NP)", code: "NP" },
    { value: "+995", label: "🇬🇪 +995 (GE)", code: "GE" },
    { value: "+998", label: "🇺🇿 +998 (UZ)", code: "UZ" },
  ];
  