import React, { useEffect, useState } from "react";
import "./Navbar.scss";

// assets
import Logo from "../../assets/logo-white.png";
import { classNames } from "../../services/util";
import { Contact, Contact2 } from "../Contact/Contact";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { navigations } from "../../constants";

const Navbar = () => {
  const [active, setActive] = useState(false);
  const [showNavbar, setShowNavbar] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;

      if (currentScrollPos > prevScrollPos) {
        // Scrolling down
        setShowNavbar(false);
      } else if (currentScrollPos === 0) {
        setShowNavbar(true);
      } else {
        // Scrolling up
        setShowNavbar(true);
      }

      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  return (
    <>
      <div
        className={classNames(
          "navbar-comp",
          showNavbar ? "active" : "",
          active ? "active" : ""
        )}
      >
        <div className="nc-logo">
          <img className="nc-image" src={Logo} alt="" />
          <div className="nc-line"></div>
          <div className="nc-content">
            <span>Next Smart Move</span>
          </div>
        </div>

        <div
          onClick={() => setActive((o) => !o)}
          className={classNames("nc-ham", active ? "active" : "")}
        >
          <div className="nch-bar bar1"></div>
          <div className="nch-bar bar2"></div>
          <div className="nch-bar bar3"></div>
          <div className="spot-spreader"></div>
        </div>
      </div>

      {active && (
        <NavPage
          close={() => {
            setActive(false);
            setTimeout(() => setShowNavbar(false), 1000);
            // setShowNavbar(false);
          }}
        />
      )}
    </>
  );
};

const NavPage = ({ close = () => {} }) => {
  const menu = [
    { content: "SERVICES", id: "services" },
    { content: "RECENT WORKS", id: "recent-works" },
    { content: "OUR SMART PROCESS", id: "our-smart-process" },
    { content: "TEAM", id: "team" },
    { content: "CAREER", id: "career" },
    { contact: "CONTACT", id: "contact" },
  ];

  // useGSAP(() => {
  //   gsap.from(".np-sections .nps-menu", {
  //     opacity: 0,
  //     y: 100,
  //     delay: 1,
  //     duration: 1,
  //     // stagger: 0.1,
  //   });
  // });

  return (
    <div className="navbar-page">
      <div className="np-sections">
        {navigations?.map((elem) => (
          <a
            href={`${elem.url}`}
            onClick={close}
            key={`nav-menu-${elem.url}`}
            className="nps-menu"
          >
            {elem.title}
            <div className="npsm-arrow"></div>
          </a>
        ))}
      </div>
    </div>
  );
};

const NavMenu = ({ elem }) => {
  return (
    <div className="nps-menu">
      {elem}
      <div className="npsm-arrow"></div>
    </div>
  );
};

export default Navbar;
